import lscache from 'lscache';
import Cookies from 'js-cookie';

import { TokensStore } from '../behaviors/tokens/types';

// time in minutes. default 6 hours
const DEFAULT_EXPIRATION = 60 * 6;

export const TOKENS = {
  // there are 5 recognized UTM parameters
  // https://en.wikipedia.org/wiki/UTM_parameters
  utmSource: {
    queryParam: 'utm_source',
    cookieName: null
  },
  utmMedium: {
    queryParam: 'utm_medium',
    cookieName: null
  },
  utmCampaign: {
    queryParam: 'utm_campaign',
    cookieName: null
  },
  utmTerm: {
    queryParam: 'utm_term',
    cookieName: null
  },
  utmContent: {
    queryParam: 'utm_content',
    cookieName: null
  },
  consumerTrackingToken: {
    queryParam: 'tt',
    cookieName: 'consumer_tracking_token'
  },
  userToken: {
    queryParam: 'ut',
    cookieName: 'consumer_user_token'
  },
  searchToken: {
    queryParam: 'st',
    cookieName: null
  },
  bcsToken: {
    queryParam: 'bcs_token',
    cookieName: null
  }
} as Record<
  keyof TokensStore,
  {
    queryParam: string;
    cookieName: string | null;
  }
>;

// exported for testing only. We use a function here to be able to mock it out
export function _getAllTokens(): Record<
  string,
  { queryParam: string; cookieName: string | null }
> {
  return TOKENS;
}

/**
 * function for managing persisted tokens off of query and cookie params.
 * If a token is found in the query params, it will be stored in local storage
 * and refreshed upon subsequent page views until the expiration time.
 * If a token is found in the cookies, it will be also stored in local storage.;
 *
 * The function only works client-side because localstorage is not available on the server
 */
export function loadPersistedTokensIntoStorage(): Record<string, string> {
  // tokens that might appear as GET params on a url

  let queryParams: URLSearchParams | null;
  try {
    // window could be undefined on server, or we could have a totally invalid query
    queryParams = new URLSearchParams(window.location.search);
  } catch (e) {
    queryParams = null;
  }

  const tokenValues: Record<string, string | null> = Object.entries(
    _getAllTokens()
  ).reduce((acc, [name, { queryParam, cookieName }]) => {
    const value =
      _getFromQuery(queryParam, queryParams) ||
      _getFromCookies(cookieName) ||
      _getFromLocalStorage(name);
    return { ...acc, [name]: value };
  }, {});

  const presentTokens: Record<string, string> = {};

  // set the tokens in local storage
  Object.entries(tokenValues).forEach(([name, value]) => {
    if (value) {
      presentTokens[name] = value;
      lscache.set(name, value, DEFAULT_EXPIRATION);
    }
  });

  return presentTokens;
}

// export for testing only
export function _getFromCookies(cookieName: string | null): string | null {
  if (!cookieName) {
    return null;
  }
  return Cookies.get(cookieName);
}

// export for testing only
export function _getFromQuery(
  queryParam: string,
  queryParams: URLSearchParams | null
): string | null {
  if (queryParams) {
    return queryParams.get(queryParam);
  }
  return null;
}

// export for testing only
export function _getFromLocalStorage(key: string): string | null {
  return lscache.get(key);
}
