import isUaWebview from 'is-ua-webview';
import isbot from 'isbot';

/**
 * Returns info about the user-agent:
 * - isWebview
 * - isBot
 * Wraps the webview `isUaWebview()` and bot `isbot()` detectors,
 * unless the callback default params are overriden.
 * @param {object} req
 * @param {function} [webviewCallback=isUaWebview] Callback defaults to `isUaWebview()`
 * @param {function} [isbotCallback=isbot] Callback defaults to `isbot()`
 * @returns {Promise<{isWebview: boolean,isBot: boolean}>} object containing isWebview and isBot, both holding boolean values
 */
async function getUserAgentInfo(
  req,
  webviewCallback = isUaWebview,
  isbotCallback = isbot
) {
  try {
    const ua = req.headers['user-agent'];
    if (ua == null) {
      throw Error(`'user-agent' is undefined in req.headers`);
    }

    if (isbotCallback === isbot) {
      /** excluding HeadlessChrome as Jenkins runs testcafe with headless Chrome */
      isbotCallback.exclude(['headlesschrome/']);
    }

    return {
      isWebview: webviewCallback(ua),
      isBot: isbotCallback(ua)
    };
  } catch (error) {
    const log = req.getLog();
    log.error(`failed to detect 'user-agent': ${error.message}`, {
      error,
      eventName: 'pmc.user_agent_detection_failed'
    });
    /** without a user-agent, assume our checks are false */
    return {
      isWebview: false,
      isBot: false
    };
  }
}

export { getUserAgentInfo };
