import querystring from 'querystring';
import axios from 'axios';
import { buildPurposesSetFromResponses } from '@kyruus/direct-book-widget';

import { visibilitySelector } from '../configuration';
import { productNameSelector } from '../product-name';
import { NEW_PATIENT, ESTABLISHED_PATIENT } from '../../utils/constants';

import { convertFacetsToSlots } from './utils';

export const SET_PATIENT_REL = 'SET_PATIENT_REL';
export const SET_PURPOSE = 'SET_PURPOSE';
export const RESET_AVAILABILITY = 'RESET_AVAILABILITY';
export const SET_PROVIDER_ID = 'SET_PROVIDER_ID';
export const PURPOSES_LOADING_START = 'PURPOSES_LOADING_START';
export const PURPOSES_LOADING_END = 'PURPOSES_LOADING_END';
export const RECEIVE_PURPOSES = 'RECEIVE_PURPOSES';
export const RECEIVE_PURPOSES_ERROR = 'RECEIVE_PURPOSES_ERROR';
export const RECEIVE_PURPOSES_SINGLE_PROVIDER =
  'RECEIVE_PURPOSES_SINGLE_PROVIDER';
export const LOADING_SLOTS_START = 'LOADING_SLOTS_START';
export const RECEIVED_SLOTS = 'RECEIVED_SLOTS';
export const LOADING_SLOTS_ERROR = 'LOADING_SLOTS_ERROR';

import {
  V8_PATIENT_REL_FILTER_KEY,
  V8_PURPOSE_FILTER_KEY,
  VALID_SLOTS_V9_FILTERS_FACETS
} from '../../utils/constants';

function isPurposeLoadingStart() {
  return {
    type: PURPOSES_LOADING_START
  };
}

function isPurposeLoadingEnd() {
  return {
    type: PURPOSES_LOADING_END
  };
}

function receivePurposes(purposes, patientRel) {
  return {
    type: RECEIVE_PURPOSES,
    patientRel,
    purposes
  };
}

function fetchPurposesError(error) {
  return {
    type: RECEIVE_PURPOSES_ERROR,
    error
  };
}

function receivePurposesSingleProvider(providerId, purposes) {
  return {
    type: RECEIVE_PURPOSES_SINGLE_PROVIDER,
    providerId,
    purposes
  };
}

function loadingSlotsStart(providerIds) {
  return {
    type: LOADING_SLOTS_START,
    providerIds
  };
}

function receivedSlots(slotsByProviderId, providerIds) {
  return {
    type: RECEIVED_SLOTS,
    slotsByProviderId,
    providerIds
  };
}

function loadingSlotsError(providerIds) {
  return {
    type: LOADING_SLOTS_ERROR,
    providerIds
  };
}

export function setPatientRel(patientRel) {
  return {
    type: SET_PATIENT_REL,
    patientRel
  };
}

export function setPurpose(purpose) {
  return {
    type: SET_PURPOSE,
    purpose
  };
}

export function resetAvailability() {
  return {
    type: RESET_AVAILABILITY
  };
}

export function fetchGlobalPurposesByPatientRel(patientRel, log) {
  return async (dispatch, getState) => {
    dispatch(isPurposeLoadingStart());
    try {
      const state = getState();
      const { customerCode } = state;
      const productName = productNameSelector(state);
      const { schedulingVisibility } = visibilitySelector(state);
      const { query = {} } = state.search;

      /**
       * We need to make a separate search call here because for global modal purposes, we need to look at
       * all providers, not filtered by any appointment purpose the user might have chosen previously
       * in the modal, else the list of purposes chosen will already be pre-filtered and we won't show all
       * possible purposes that the user can choose
       */

      // only copy filters unrelated to appointment_ehr_purposes in order to
      // keep the results global across providers for the given search
      let queryFilters;
      if (query.filter && Array.isArray(query.filter)) {
        // if query.filter is an array of filters
        queryFilters = query.filter;
      } else if (query.filter) {
        // else if query.filter is a single filter
        queryFilters = [query.filter];
      }

      const filteredQueryFilters = (queryFilters || []).filter((filter) => {
        return (
          !filter.startsWith(V8_PATIENT_REL_FILTER_KEY) &&
          !filter.startsWith(V8_PURPOSE_FILTER_KEY)
        );
      });

      const searchParams = {
        ...query,
        // remove patient rel and purpose filters if they exist
        filter: filteredQueryFilters
      };

      // search call here
      const searchResponse = await axios.get(
        `/api/search?${querystring.stringify(searchParams)}`,
        {
          withCredentials: true
        }
      );

      const providerIds = searchResponse.data.data.search_context || [];

      let params = {
        filter: [
          `patient_relationship:${patientRel}`,
          `visibilities.${schedulingVisibility}`
        ],
        // visit-method field is used for purpose filtering
        facet: 'purpose#visit_method',
        _filter: `provider_id:${providerIds.join('#')}`
      };

      const copyOfQuery = {
        ...query
      };

      if (filteredQueryFilters.length > 0) {
        params.filter = params.filter.concat(filteredQueryFilters);
      }

      delete copyOfQuery.filter;

      params = {
        ...params,
        ...copyOfQuery
      };

      // only send filters which are valid for v9 slots
      params.filter = params.filter.filter((filter) => {
        const [filterKey] = filter.split(':');
        return VALID_SLOTS_V9_FILTERS_FACETS.includes(filterKey);
      });

      const response = await axios.post(
        `/api/searchservice-v9/${encodeURIComponent(customerCode)}/slots`,
        // send data in POST body:
        // searchContext may be too long to be transmitted in a URL
        JSON.stringify(params),
        {
          headers: {
            'Content-Type': 'application/json',
            'x-consumer-groups': customerCode,
            'x-consumer-username': productName
          }
        }
      );

      dispatch(receivePurposes(response.data.facets, patientRel));
      dispatch(isPurposeLoadingEnd());

      log('user_action.page_view_provider_availability_appt_purposes');
    } catch (error) {
      dispatch(fetchPurposesError(error));
    }
  };
}

export function fetchGlobalPurposesByPatientRelV9(patientRel, log) {
  return async (dispatch, getState) => {
    dispatch(isPurposeLoadingStart());
    try {
      const state = getState();
      const { customerCode } = state;
      const productName = productNameSelector(state);
      const { query = {} } = state.search;
      const { schedulingVisibility } = visibilitySelector(state);

      /**
       * We need to make a separate search call here because for global modal purposes, we need to look at
       * all providers, not filtered by any appointment purpose the user might have chosen previously
       * in the modal, else the list of purposes chosen will already be pre-filtered and we won't show all
       * possible purposes that the user can choose
       */

      // only copy filters unrelated to appointment_ehr_purposes in order to
      // keep the results global across providers for the given search
      let queryFilters;
      if (query.filter && Array.isArray(query.filter)) {
        // if query.filter is an array of filters
        queryFilters = query.filter;
      } else if (query.filter) {
        // else if query.filter is a single filter
        queryFilters = [query.filter];
      }

      const filteredQueryFilters = (queryFilters || []).filter((filter) => {
        return (
          !filter.startsWith(V8_PATIENT_REL_FILTER_KEY) &&
          !filter.startsWith(V8_PURPOSE_FILTER_KEY)
        );
      });

      const {
        searchV9: { searchContext }
      } = state;

      let params = {
        filter: [
          `patient_relationship:${patientRel}`,
          `visibilities.${schedulingVisibility}`
        ],
        // visit-method field is used for purpose filtering
        facet: 'purpose#visit_method',
        _filter: `provider_id:${searchContext.join('#')}`
      };

      const copyOfQuery = {
        ...query
      };

      if (filteredQueryFilters.length > 0) {
        params.filter = params.filter.concat(filteredQueryFilters);
      }

      delete copyOfQuery.filter;

      params = {
        ...params,
        ...copyOfQuery
      };

      const response = await axios.post(
        `/api/searchservice-v9/${encodeURIComponent(customerCode)}/slots`,
        // send data in POST body:
        // searchContext may be too long to be transmitted in a URL
        JSON.stringify(params),
        {
          headers: {
            'Content-Type': 'application/json',
            'x-consumer-groups': customerCode,
            'x-consumer-username': productName
          }
        }
      );

      dispatch(receivePurposes(response.data.facets, patientRel));
      dispatch(isPurposeLoadingEnd());

      log('user_action.page_view_provider_availability_appt_purposes');
    } catch (error) {
      dispatch(fetchPurposesError(error));
    }
  };
}

export function fetchPurposesByProviderId(providerId, log) {
  return async (dispatch, getState) => {
    const state = getState();
    const { customerCode } = state;
    const productName = productNameSelector(state);
    const { schedulingVisibility } = visibilitySelector(state);
    dispatch(isPurposeLoadingStart());
    try {
      const getParams = (relationship) => {
        return {
          filter: [
            `visibilities.${schedulingVisibility}`,
            `provider_id:${providerId}`,
            `patient_relationship:${relationship}`
          ],
          facet: 'purpose#visit_method'
        };
      };

      const [establishedPurposesResponse, newPurposesResponse] =
        await Promise.all([
          axios.get(
            `/api/searchservice-v9/${encodeURIComponent(
              customerCode
            )}/slots?${querystring.stringify(getParams(ESTABLISHED_PATIENT))}`,
            {
              headers: {
                'x-consumer-groups': customerCode,
                'x-consumer-username': productName
              }
            }
          ),
          axios.get(
            `/api/searchservice-v9/${encodeURIComponent(
              customerCode
            )}/slots?${querystring.stringify(getParams(NEW_PATIENT))}`,
            {
              headers: {
                'x-consumer-groups': customerCode,
                'x-consumer-username': productName
              }
            }
          )
        ]);

      const purposes = buildPurposesSetFromResponses({
        establishedPurposesResponse: establishedPurposesResponse.data,
        newPurposesResponse: newPurposesResponse.data
      });

      dispatch(receivePurposesSingleProvider(providerId, purposes));
      dispatch(isPurposeLoadingEnd());

      log('user_action.page_view_provider_availability_appt_purposes');
    } catch (error) {
      dispatch(fetchPurposesError(error));
    }
  };
}

export function fetchSlots(apptInfo, providerIds) {
  return async function (dispatch, getState) {
    const state = getState();
    const { customerCode } = state;
    const productName = productNameSelector(state);
    const { schedulingVisibility } = visibilitySelector(state);
    dispatch(loadingSlotsStart(providerIds));

    const params = {
      _filter: `provider_id:${providerIds.join('#')}`,
      filter: [
        `patient_relationship:${apptInfo.relationship}`,
        `purpose:${apptInfo.purpose}`,
        `visibilities.${schedulingVisibility}`
      ],
      facet: 'provider_id#location_id#date#start',
      sort: 'start_time'
    };

    try {
      const response = await axios.get(
        `/api/searchservice-v9/${encodeURIComponent(
          customerCode
        )}/slots?${querystring.stringify(params)}`,
        {
          headers: {
            'x-consumer-groups': customerCode,
            'x-consumer-username': productName
          }
        }
      );

      const data = response.data;

      let slotsByProviderId;

      if (data.facets.length) {
        slotsByProviderId = convertFacetsToSlots(data.facets, apptInfo);
      } else {
        slotsByProviderId = providerIds.reduce((acc, id) => {
          if (!acc[id]) {
            acc[id] = {
              slots: [],
              apptInfo
            };
          }
          return acc;
        }, {});
      }

      await dispatch(receivedSlots(slotsByProviderId, providerIds));
    } catch (error) {
      await dispatch(loadingSlotsError(providerIds));
    }
  };
}
