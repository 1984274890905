import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  INVALID_POST_MESSAGE_URL: {
    id: 'crm.error.invalid_post_message_url',
    description:
      'Error that occurs when ProviderMatch cannot communicate with the CRM system due to an invalid post message URL.',
    defaultMessage:
      'A validation error occurred. Bookings and referrals are currently unavailable. Try again later or contact an administrator.'
  },
  REQUEST_PATIENT_FAILED: {
    id: 'crm.error.request_patient_failed',
    description:
      'Error that occurs when ProviderMatch is unable to request patient data from the CRM system due to an internal error.',
    defaultMessage:
      'A validation error occurred. Bookings and referrals are currently unavailable. Try again later or contact an administrator.'
  },
  NO_PATIENT_DATA_RECEIVED: {
    id: 'crm.error.no_patient_data_received',
    description:
      'Error that occurs when ProviderMatch receives no patient data from the CRM system.',
    defaultMessage:
      'A validation error occurred. Bookings and referrals are currently unavailable. Try again later or contact an administrator.'
  },
  SENDING_BOOKING_DATA_ERROR: {
    id: 'crm.error.sending_booking_data_error',
    description:
      'Error that occurs when ProviderMatch is unable to send booking data to the CRM system.',
    defaultMessage:
      'An error occurred saving the appointment to the patient record. Verify if the booking was successful and contact an administrator.'
  },
  SENDING_REFERRAL_DATA_ERROR: {
    id: 'crm.error.sending_referral_data_error',
    description:
      'Error that occurs when ProviderMatch is unable to send referral data to the CRM system.',
    defaultMessage:
      'An error occurred saving the referral to the patient record. Please contact an administrator.'
  }
});
