import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useReferralConnector as useReferral } from '@kyruus/provider-referral';
import { MODULES, isModuleEnabled } from 'Common/config';

export const ReferralConnectorContext = React.createContext();

export const ReferralConnectorProvider = connect()(
  injectIntl(function ReferralConnectorProvider({
    log,
    config,
    machineOptions = {},
    ...props
  }) {
    const referralEnabled = isModuleEnabled(config, MODULES.REFERRAL);
    const crmEnabled = isModuleEnabled(config, MODULES.CRM_INTEGRATION);

    const referralConnector = useReferral({
      initialContext: { ...config?.referral },
      machineOptions: {
        ...machineOptions,
        actions: {
          ...(machineOptions.actions ? machineOptions.actions : {}),
          onInitialization: (ctx, event) => {
            if (referralEnabled && !crmEnabled) {
              machineOptions?.actions?.onInitialization?.(ctx, event);
              log?.('referral.integration.initialization.success');
            }
          }
        }
      }
    });

    // ToDo : ( KENG-40130) Remove the enabled field from the context, instead add `module` field to indicate if its referral module
    const value = {
      enabled: crmEnabled,
      connector: referralConnector
    };

    return <ReferralConnectorContext.Provider value={value} {...props} />;
  })
);
