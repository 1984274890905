// set tracking cookies
import {
  CONSUMER_TRACKING_TOKEN,
  SEARCH_TOKEN,
  USER_TOKEN,
  SEARCH_SHUFFLE_TOKEN,
  getToken as getUuidToken
} from './uuid-tracking-tokens';

import { getBaseCookieParams } from './tracking-tokens';

import { getRwgTokenValues, saveRwgTokenValues } from './google-rwg-tokens';

import { functionCachedPerRequest } from './cache-per-request';
import { getUserAgentInfo } from './get-user-agent-info';

/**
 * Assign functions to req to fetch various session tokens
 * @param {} req
 * @param {*} res
 * @param {*} next
 * @returns
 */
export function assignSessionTokenFunctions(req) {
  // guarantee we can only run this once
  if (req.sessionTokenFunctionsAssigned) {
    return;
  }

  req.getConsumerTrackingToken = functionCachedPerRequest({ req }, (req) =>
    getUuidToken(req, CONSUMER_TRACKING_TOKEN)
  );
  req.getSearchToken = functionCachedPerRequest({ req }, (req) =>
    getUuidToken(req, SEARCH_TOKEN)
  );
  req.getUserToken = functionCachedPerRequest({ req }, (req) =>
    getUuidToken(req, USER_TOKEN)
  );
  req.getSearchShuffleToken = functionCachedPerRequest({ req }, async (req) => {
    const { isBot } = await getUserAgentInfo(req);
    if (isBot) {
      return null;
    }
    return getUuidToken(req, SEARCH_SHUFFLE_TOKEN);
  });

  req.getGoogleConversionRwgTokenValues = functionCachedPerRequest(
    { req },
    (req) => getRwgTokenValues(req)
  );

  req.sessionTokenFunctionsAssigned = true;
}

/**
 * Save various session tokens to their various cookies
 * @param {} req
 * @param {*} res
 * @param {*} next
 * @returns
 */
export async function saveSessionTokens(req, res) {
  const appSettings = await req.getAppSettings();

  const cookieParams = await getBaseCookieParams(req);

  const consumerTrackingToken = await req.getConsumerTrackingToken();
  if (consumerTrackingToken) {
    res.cookie(CONSUMER_TRACKING_TOKEN.cookieName, consumerTrackingToken, {
      ...cookieParams,
      maxAge: appSettings['SECONDS_UNTIL_TRACKING_TOKEN_EXPIRES'] * 1000
    });
  }

  const searchShuffleToken = await req.getSearchShuffleToken();
  if (searchShuffleToken) {
    res.cookie(SEARCH_SHUFFLE_TOKEN.cookieName, searchShuffleToken, {
      ...cookieParams,
      maxAge: appSettings['SECONDS_UNTIL_TRACKING_TOKEN_EXPIRES'] * 1000
    });
  }

  const userToken = await req.getUserToken();
  if (userToken) {
    res.cookie(USER_TOKEN.cookieName, userToken, {
      ...cookieParams,
      maxAge: appSettings['SECONDS_UNTIL_USER_TOKEN_EXPIRES'] * 1000
    });
  }

  await saveRwgTokenValues(req, res);
}
