import { useCallback, useEffect, useState } from 'react';
import _debounce from 'lodash/debounce';
import _memoize from 'lodash/memoize';

const UPDATE_SUGGESTIONS_DEBOUNCE = 200;

export async function _getPlacesData(input) {
  let data;

  try {
    const response = await fetch(`/aws-maps/autocomplete/${input}`);
    if (!response.ok) {
      throw new Error(response.message);
    }
    data = await response.json();
  } catch (error) {
    // Return empty data if the places endpoint is unavailable/returns bad data
    data = {};
  }

  return data;
}

export default function useSuggestions({ term }) {
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);

  const getPlacesDataMemoized = useCallback(_memoize(_getPlacesData), []);

  useEffect(() => {
    updateSuggestions(term);
  }, [term, updateSuggestions]);

  const updateSuggestions = useCallback(
    (inputValue) => {
      // don't make an autocomplete call when a user selects an option from the dropdown
      if (suggestions.includes(inputValue)) return;
      const handle = _debounce(async (inputValue) => {
        let suggestions = [];

        if ((inputValue || '').trim() !== '') {
          setSuggestionsLoading(true);
          const placesData = await getPlacesDataMemoized(inputValue);
          suggestions = ((placesData && placesData.Results) || [])
            .filter((result) => result.PlaceId)
            .map((result) => result.Text);
        }

        setSuggestions(suggestions);
        setSuggestionsLoading(false);
      }, UPDATE_SUGGESTIONS_DEBOUNCE);
      return handle(inputValue);
    },
    [suggestions, getPlacesDataMemoized]
  );

  return { suggestions, suggestionsLoading };
}
