import React from 'react';
import { InvalidFieldList, MessageWrapper } from './styles';
import { KyruusFormattedMessage } from '@kyruus/intl';
import { messages } from './messages';

export const InvalidPatientDataMessage = ({
  type,
  invalidFields = [],
  color
}) => {
  return (
    <MessageWrapper>
      <KyruusFormattedMessage {...messages[type]} />
      {invalidFields.length > 0 ? (
        <InvalidFieldList color={color}>
          {invalidFields.map((field) => {
            return (
              <KyruusFormattedMessage
                key={field}
                tagName="li"
                {...messages[field]}
              />
            );
          })}
        </InvalidFieldList>
      ) : null}
    </MessageWrapper>
  );
};
