import querystring from 'querystring';
import _omit from 'lodash/omit';
import _cloneDeep from 'lodash/cloneDeep';
import _upperFirst from 'lodash/upperFirst';

import { settingsSelector } from '../../settings';
import { configSelector } from '../../configuration';
import {
  V9_QUERY_PARAMS_BLACKLIST,
  PRIMARY_MLOC_FACET,
  V9_DIRECT_BOOK_CAPABLE_FILTER,
  PROVIDER_ID_FIELD_NAME,
  V9_RESPONSE_CONTEXT_PARAMS_BLACKLIST,
  LOCATION_FACET_DEFAULT_SORT_ORDER,
  LOCATION_FACET_DEFAULT_DISTANCE
} from '../../../utils/constants';
import { isSearchAlertsEnabled } from 'Common/config';
import { getResolvedSearchLocation } from '../../../utils/location';
import { logSentryError } from '../../../utils/logSentryError';
import { splitOnce } from 'Common/utils/splitOnce';

export async function constructV9QueryParams(nextLocation, state) {
  const customerConfig = configSelector(state);
  const appSettings = settingsSelector(state);
  const urlParams = querystring.parse(nextLocation.search.slice(1));
  const queryParams = {
    ..._omit(urlParams, V9_QUERY_PARAMS_BLACKLIST),
    search_alerts: isSearchAlertsEnabled(customerConfig) ? true : false,
    context: customerConfig.index || state.customerCode,
    tracking_token: state.tokens?.consumerTrackingToken,
    search_token: state.tokens?.searchToken,
    user_id: state.tokens?.anonymousUserId,
    user_token: state.tokens?.userToken,
    shuffle_seed: state.tokens?.searchShuffleToken,
    per_page: appSettings.PROVIDERS_PER_PAGE,
    provider_fields: ['-clinical_keywords'].join(','),
    facet: [
      ...customerConfig.facets_v9.map(({ field }) => [field]),
      [PRIMARY_MLOC_FACET]
    ]
  };

  // append options `components`
  if (
    customerConfig.search_params?.geocoding?.components?.administrative_area
  ) {
    queryParams.components = `administrative_area:${customerConfig.search_params.geocoding.components.administrative_area}`;
  }
  if (!queryParams.location) {
    return queryParams;
  }

  let resolvedSearchLocation = queryParams.location;
  try {
    resolvedSearchLocation = await getResolvedSearchLocation(
      queryParams.location
    );
  } catch (e) {
    //   // could not get coords for location,
    //   // we'll pass location as we got it and hope SS can deal with it
    logSentryError(e);
  }
  return {
    ...queryParams,
    display_location: queryParams.display_location ?? queryParams.location,
    location: resolvedSearchLocation
  };
}

export function constructParamsForSearchSdk(state, queryParams, req) {
  const queryParamsForSearchSdk = _cloneDeep(queryParams);

  if (
    queryParamsForSearchSdk.filter &&
    queryParamsForSearchSdk.filter.includes(V9_DIRECT_BOOK_CAPABLE_FILTER)
  ) {
    // we only need one search call to get searchData + searchContext
    queryParamsForSearchSdk.facet.push([PROVIDER_ID_FIELD_NAME]);
  }

  if (
    queryParamsForSearchSdk.filter ||
    queryParamsForSearchSdk._filter ||
    queryParamsForSearchSdk['-filter']
  ) {
    queryParamsForSearchSdk.filter = transformFilterUrlParamsForSearchSdk(
      queryParamsForSearchSdk.filter,
      queryParamsForSearchSdk._filter,
      queryParamsForSearchSdk['-filter']
    );
  }

  if (req?.params?.specialty) {
    queryParamsForSearchSdk.specialties = req.params.specialty;
  }

  if (req?.params?.location) {
    // this logic was migrated from /providermatch_consumer/api/helpers/search_service.py's `search_response` method
    queryParamsForSearchSdk.location = req.params.location;
    queryParamsForSearchSdk.sort = getDistanceSortOrder(state);
    queryParamsForSearchSdk.distance = getDefaultDistance(state);
  }

  return queryParamsForSearchSdk;
}

export function constructResponseContext(state, queryParams, req) {
  // remove params that should not be reflected in the page URL
  const searchParams = _omit(queryParams, V9_RESPONSE_CONTEXT_PARAMS_BLACKLIST);

  if (req?.params?.specialty) {
    searchParams.specialties = req.params.specialty;
  }

  if (req?.params?.location) {
    // this logic was migrated from /providermatch_consumer/api/helpers/search_service.py's `search_response` method
    searchParams.location = req.params.location;
    searchParams.sort = getDistanceSortOrder(state);
    searchParams.distance = getDefaultDistance(state);
  }

  return {
    searchParams,
    config: configSelector(state),
    perPage: settingsSelector(state).PROVIDERS_PER_PAGE,
    isSpecialtyLocationSSR: Boolean(
      req?.params?.specialty || req?.params?.location
    )
  };
}

export function getDistanceSortOrder(state) {
  const locationFacet = configSelector(state).location_facet || {};
  const sortOrder =
    locationFacet.custom_sort_order || LOCATION_FACET_DEFAULT_SORT_ORDER;
  return sortOrder;
}

export function getDefaultDistance(state) {
  const locationFacet = configSelector(state).location_facet || {};
  const defaultDistance =
    locationFacet.miles || LOCATION_FACET_DEFAULT_DISTANCE;
  return defaultDistance;
}

export function transformFilterUrlParamsForSearchSdk(
  andFilters = [],
  orFilters = [],
  notFilters = []
) {
  const result = {
    and: {
      and: {},
      or: {}
    },
    or: {},
    not: {}
  };

  if (andFilters && !Array.isArray(andFilters)) {
    andFilters = [andFilters];
  }
  if (orFilters && !Array.isArray(orFilters)) {
    orFilters = [orFilters];
  }
  if (notFilters && !Array.isArray(notFilters)) {
    notFilters = [notFilters];
  }

  const stringToBoolIfBoolString = (string) => {
    const stringsToBools = {
      true: true,
      false: false
    };
    if (stringsToBools[string] !== undefined) {
      return stringsToBools[string];
    }
    return string;
  };

  for (const filterParam of andFilters) {
    // eslint-disable-next-line prefer-const
    let [k, v] = splitOnce(decodeURIComponent(filterParam), ':');
    if (v.includes('#')) {
      const predicates = v.split('#').map(stringToBoolIfBoolString);
      if (!result.and.or[k]) {
        result.and.or[k] = predicates;
      } else {
        result.and.or[k].concat(predicates);
      }
      continue;
    }
    v = stringToBoolIfBoolString(v);
    if (!result.and.and[k]) {
      result.and.and[k] = [v];
    } else {
      result.and.and[k].push(v);
    }
  }

  for (const filterParam of orFilters) {
    // eslint-disable-next-line prefer-const
    let [k, v] = splitOnce(decodeURIComponent(filterParam), ':');
    if (v.includes('#')) {
      const predicates = v.split('#').map(stringToBoolIfBoolString);
      if (!result.or[k]) {
        result.or[k] = predicates;
      } else {
        result.or[k].concat(predicates);
      }
      continue;
    }
    v = stringToBoolIfBoolString(v);
    if (!result.or[k]) {
      result.or[k] = [v];
    } else {
      result.or[k].push(v);
    }
  }

  for (const filterParam of notFilters) {
    // eslint-disable-next-line prefer-const
    let [k, v] = splitOnce(decodeURIComponent(filterParam), ':');
    if (v.includes('#')) {
      const predicates = v.split('#').map(stringToBoolIfBoolString);
      if (!result.not[k]) {
        result.not[k] = predicates;
      } else {
        result.not[k].concat(predicates);
      }
      continue;
    }
    v = stringToBoolIfBoolString(v);
    if (!result.not[k]) {
      result.not[k] = [v];
    } else {
      result.not[k].push(v);
    }
  }

  return result;
}

export function getSuggestionsForDisplay(suggestions) {
  let result = [];

  Object.entries(suggestions).forEach(
    ([suggestedSearchType, suggestedSearchValues]) => {
      result = result.concat(
        suggestedSearchValues.map((suggestion) => ({
          display_text: suggestion.split(' ').map(_upperFirst).join(' '),
          display_search_type: suggestedSearchType,
          query_modifications: [
            { action: 'delete_key', key: 'unified' },
            {
              action: 'append',
              key: suggestedSearchType,
              value: suggestion
            }
          ]
        }))
      );
    }
  );

  return result;
}

export function splitObjectKeys(arr) {
  return arr.map((item) => {
    const [key, value] = splitOnce(item, ':');
    return {
      [key]: value
    };
  });
}

/**
 * Utility to convert a list of filters into a list of OR filters. If multiple filters are
 * being applied via the same facet, we want to use an OR within that facet. The difference
 * being this:
 *
 * ?filter=provider.foo:bar&filter=provider.foo:baz
 *
 * vs. this:
 *
 * ?filter=provider.foo:bar#baz
 *
 * ['provider.foo:bar', 'provider.foo:baz] -> ['provider.foo:bar#baz]
 *
 * @param {Array<string>} filters the array of filters to convert
 * @returns {Array<string>}
 */
export function convertToOrFilters(filters) {
  try {
    const map = {};
    const result = [];

    filters.forEach((filter) => {
      const [filterKey, filterValue] = splitOnce(filter, ':');
      map[filterKey] = map[filterKey]
        ? map[filterKey] + `#${filterValue}`
        : filterValue;
    });

    Object.entries(map).forEach(([filterKey, filterValue]) => {
      result.push(`${filterKey}:${filterValue}`);
    });

    return result;
  } catch (e) {
    return filters;
  }
}

/**
 * Utility to convert a list of OR filters into a list of AND filters. Basically does the opposite
 * of convertToOrFilters above. The difference being this:
 *
 * ?filter=provider.foo:bar#baz
 *
 * vs. this:
 *
 * ?filter=provider.foo:bar&filter=provider.foo:baz
 *
 * ['provider.foo:bar#baz] -> ['provider.foo:bar', 'provider.foo:baz]
 *
 * This is used to get the existing logic to recognize when OR filters are applied (render as
 * selected filters in the filter bar)
 *
 * @param {Array<string>} filters the array of filters to convert
 * @returns {Array<string>}
 */
export function convertToAndFilters(filters) {
  try {
    const result = [];

    filters.forEach((filter) => {
      const [filterKey, filterValue] = splitOnce(filter, ':');
      filterValue.split('#').forEach((value) => {
        result.push(`${filterKey}:${value}`);
      });
    });

    return result;
  } catch (e) {
    return filters;
  }
}
