import React from 'react';
import { withRouter } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { KyruusFormattedMessage, kyruusFormatMessage } from '@kyruus/intl';
import _filter from 'lodash/filter';

import { ClearFilterLink } from '../../styles';

const messages = defineMessages({
  clear: {
    id: 'facet.clearall',
    description: 'Text to clear all applied filters on the facet',
    defaultMessage: 'Clear'
  },
  clearallAriaLabel: {
    id: 'facet.clearallAriaLabel',
    description: 'Text to clear all applied filters on the facet',
    defaultMessage: 'Clear All {facetName} filters'
  },
  searchFilterFormDescription: {
    id: 'facet.form.description',
    description: 'Accessibility description for the search filter form.',
    defaultMessage:
      'Changing an input in this form will immediately trigger a new search and subsequent change of context.'
  }
});

export const ClearFilter = withRouter(
  injectIntl(
    ({
      config,
      facetName,
      getUpdatedSearch,
      intl,
      labelName,
      log,
      onClick,
      searchableLocation
    }) => {
      let modifications = [];
      if (config.type === 'location') {
        modifications.push({ action: 'delete_key', key: 'distance' });
        if (searchableLocation) {
          modifications.push({ action: 'delete_key', key: 'location' });
          modifications.push({ action: 'delete_key', key: 'display_location' });
        }
      } else {
        modifications = (_filter(config.terms, 'applied') || []).map(
          (appliedFilter) => {
            return {
              action: 'delete_key_value',
              key: 'filter',
              value: appliedFilter.filter_param
            };
          }
        );
      }
      const clearedSearch = getUpdatedSearch(modifications);

      const handleClick = () => {
        if (onClick) {
          onClick();
        }
        log('user_action.search_results.clear_filters', {
          facet_cleared: config.field
        });
      };

      return (
        <ClearFilterLink
          to={clearedSearch}
          onClick={handleClick}
          id={'clear-' + facetName}
          data-testid={'clear-' + facetName}
          aria-label={kyruusFormatMessage(intl, messages.clearallAriaLabel, {
            facetName: kyruusFormatMessage(intl, labelName)
          })}
        >
          <KyruusFormattedMessage {...messages.clear} />
        </ClearFilterLink>
      );
    }
  )
);

ClearFilter.displayName = 'ClearFilter';
